import { Box } from "@mui/joy";
import HomePage from "./Home";
import LoginPage from "./Login";
import OAuth from "./OAuth";
import PrivacyPage from "./Privacy";
import { RequireAuth } from "./auth";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Box width="100vw" height="100vh">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/authorize"
          element={
            <RequireAuth>
              <OAuth />
            </RequireAuth>
          }
        />
      </Routes>
    </Box>
  );
}

export default App;
