import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/inter";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth";
import { CssBaseline, CssVarsProvider } from "@mui/joy";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <CssVarsProvider>
          <CssBaseline />
          <App />
        </CssVarsProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
