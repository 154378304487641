import { Sheet, Typography } from "@mui/joy";

function HomePage() {
  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 450,
        mx: "auto",
        my: 4,
        py: 3,
        px: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
    >
      <Typography level="h1" component="h1">
        AI Mastery Mentor
      </Typography>
    </Sheet>
  );
}

export default HomePage;
