import { Sheet, Typography } from "@mui/joy";

function PrivacyPage() {
  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 450,
        mx: "auto",
        my: 4,
        py: 3,
        px: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
    >
      <Typography level="h4" component="h1">
        Privacy Policy
      </Typography>
      <Typography level="body1">
        AI Mastery Mentor is a demo application and stores user information in a
        database. This information is used to provide a personalized experience
        for the user.
      </Typography>
    </Sheet>
  );
}

export default PrivacyPage;
