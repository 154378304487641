import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  let [token, setToken] = useState(false);

  useEffect(() => {
    const expires = localStorage.getItem("expires");
    const token = localStorage.getItem("token");
    if (expires > Date.now() && token) {
      setToken(token);
    } else {
      setToken(null);
    }
  }, []);

  const login = async (username, passphrase) => {
    const response = await fetch("/auth/login", {
      body: JSON.stringify({ username, passphrase }),
      method: "POST",
    });

    if (response.status !== 200) {
      throw new Error("Invalid username or passphrase");
    }

    const { token, expires_in } = await response.json();

    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expires", Date.now() + expires_in * 1000);
  };

  const logout = async () => {
    await fetch("/auth/logout", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expires");
  };

  const value = { token, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.token === null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
