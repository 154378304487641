import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "./auth";
import { Button, Sheet, Stack, Typography } from "@mui/joy";

function OAuth() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const handleAuthorize = useCallback(async () => {
    const state = searchParams.get("state");

    try {
      const response = await fetch(`/oauth/authorize`, {
        body: JSON.stringify({ token: auth.token }),
        method: "POST",
      });
      const result = await response.json();

      document.location.assign(
        searchParams.get("redirect_uri") + `?state=${state}&code=${result.code}`
      );
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  }, [auth, searchParams]);

  if (!searchParams.has("redirect_uri") || !searchParams.has("state")) {
    return (
      <Sheet
        variant="outlined"
        sx={{
          width: 450,
          mx: "auto",
          my: 4,
          py: 3,
          px: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <Typography level="h4" component="h1">
          Authorize Application
        </Typography>
        <Typography level="body1">
          The link you folllowed is invalid. Please go back and try again.
        </Typography>
      </Sheet>
    );
  }

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 450,
        mx: "auto",
        my: 4,
        py: 3,
        px: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
    >
      <Typography level="h4" component="h1">
        Authorize Application
      </Typography>

      <Typography level="body1">
        <strong>ChatGPT</strong> is requesting access to your <strong>AI Mastery Mentor</strong> account.
      </Typography>

      <Stack spacing={2} direction="row">
        <Button onClick={handleAuthorize}>Authorize</Button>
        <Button onClick={auth.logout} color="warning">Sign Out</Button>
      </Stack>
    </Sheet>
  );
}

export default OAuth;
