import { useCallback, useState } from "react";
import { useAuth } from "./auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";

function LoginPage() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [passphrase, setPassphrase] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await auth.login(username, passphrase);
        navigate(location.state?.from || "/", { replace: true });
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    },
    [location, navigate, auth, username, passphrase]
  );

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 450,
        mx: "auto",
        my: 4,
        py: 3,
        px: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
    >
      <Typography level="h4" component="h1">
        Welcome!
      </Typography>
      <Typography level="body-sm">Log in to continue.</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>Passphrase</FormLabel>
          <Input
            type="password"
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            required
          />
        </FormControl>
        <Button type="submit" sx={{ mt: 1 }}>
          Log in
        </Button>
      </form>
    </Sheet>
  );
}

export default LoginPage;
